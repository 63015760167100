/**
 * Custom elements polyfills
 */
import '@webcomponents/custom-elements/custom-elements.min';
import '@webcomponents/custom-elements/src/native-shim';
import 'web-animations-js';

/**
 * EcmaScript polyfills
 */
import 'core-js/es';
import 'core-js/proposals/reflect-metadata';

/**
 * Zone.js is not a polyfill.
 * It should be dropped after migrating to Ivy
 */
import 'zone.js/dist/zone';
